<template>
  <b-modal
    ref="modal"
    :title="title"
    size="lg"
    @ok="save"
    @hidden="clear"
  >
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label>Seleccione tipo de transacción</label>
          <v-select
            v-model="model.transaction_type_id"
            label="text"
            :options="transactionTypes"
            :clearable="false"
            :reduce="value=>value.getAttr('id')"
            :get-option-label="option=>option.getAttr('name')"
          />
        </div>

        <div class="form-group">
          <label>Método de transacción</label>
          <input
            v-model="model.payment_gateway"
            type="text"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label>Monto</label>
          <input
            v-model="model.amount"
            type="number"
            class="form-control"
          >
        </div>
        <div class="form-group">
          <label>Concepto</label>
          <textarea
            v-model="model.concept"
            class="form-control"
            style="height: 70px; resize: none;"
          />
        </div>

      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'AccountTransactionFormModal',
  components: {
    BModal, vSelect,
  },
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    account_id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: 'Seleccionar archivo',
    },
    transactionTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      model: {
        account_id: null,
        transaction_type_id: null,
        amount: null,
        concept: '',
        payment_gateway: null,
      },
    }
  },
  mounted() {
    this.model.account_id = this.account_id
  },
  methods: {
    show() {
      this.$refs.modal.show()
    },
    hide() {
      this.$refs.modal.hide()
      this.clear()
    },
    clear() {
      this.model = {
        account_id: null,
        transaction_type_id: null,
        amount: null,
        concept: '',
        payment_gateway: null,
      }
    },
    save() {
      this.$emit('save', this.model)
      this.hide()
    },
  },
}
</script>

<style scoped>

</style>
