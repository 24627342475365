import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import TransactionType from '@/dc-it/models/TransactionType'
import Relation from '@/dc-it/types/Relation'
import helpers from '@/dc-it/services/helpers'

export default class AccountTransaction extends Model {
    endpoint = 'account_transactions';

    related = ['transaction_type'];

    fields = [
      new Field('id', 'Código').hide(),
      new Relation('transaction_type', 'Tipo de transacción', TransactionType, 'name'),
      new Field('amount', 'Monto', 'number')
        .noFilterable()
        .applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('before_amount', 'Saldo anterior', 'number')
        .disabled()
        .noFilterable()
        .applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('after_amount', 'Saldo posterior', 'number')
        .disabled()
        .noFilterable()
        .applyMask(val => `$${helpers.numberFormat(val)}`),
      new Field('concept', 'Concepto'),
      new Field('payment_gateway', 'Pasarela de pago'),
      new Field('created_at', 'Fecha de creación')
        .disabled()
        .applyMask(val => {
          const date = new Date(val)
          return helpers.dateFormat(date, true)
        }),
    ]

    clone=() => AccountTransaction
}
